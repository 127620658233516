export const configcode = {
  debug: false, //enable console.log
  // ------------- Production Settings ----------------
  api: "api",
  baseSelfUrl: "https://frontend.saijai-crm.com",
  
  //base saijai commission api url
  baseUrl: "https://backend.saijai-crm.com",

  //base saijai pos web url
  basePOSUrl: "https://saijaipos.saijai-crm.com",

  //base saijai api blob url
  bzbsBlobUrl: "https://buzzebees.blob.core.windows.net",
  basePublic: "https://public.saijai-crm.com",
  themeVersion: 3,
};
